<template>
    <div class="mycapability"  v-touch:swipe.left="gotoPage" v-touch:swipe.right="onSwipeRight">
        <div class="back" @click="onSwipeRight">
          <img src="../assets/img/home/back.png" alt="">
        </div>
        <div class="capability-box2"></div>
        <div class="compared" @click="openCompared" v-if="!showVideo">
          {{isCompared?'取消对比':'对比'}}
        </div>
        <div class="play-box" v-if="showVideo">
            <div class="quit" @click="onquit">退出</div>
            <div class="videoarr">
                <div class="video-item" v-for="(item,index) in videourl" :key="index">
                    <video controls="controls" :src="item" autoplay="autoplay"></video>
                </div>
            </div>
        </div>
        <div class="videos" style="top:430px" v-if="cover">
            <div class="thisvideo">
              {{cover.standard_capability}}
                <!-- <div class="jiao"></div>
                <div class="thisvideo-box">
                    <img :src="cover.excellent+
                    '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_444,h_267,m_fast'"
                    alt="">
                    <div class="title-bottom">
                         <div class="vbox">
                            <div class="vtitle">{{cover.standard_capability}}</div>
                         </div>
                    </div>
                </div> -->
            </div>
            <div class="videos-list" v-if="cover">
                <div class="video-item" @click="selectVideo(cover.pass)">
                  <img src="../assets/img/capability/select2.png" alt=""
                        class="select" v-if="videourl.indexOf(cover.pass)===-1&&isCompared">
                    <img src="../assets/img/capability/select.png" alt=""
                     class="select" v-if="videourl.indexOf(cover.pass)>=0&&isCompared">
                    <img class="bg" :src="cover.pass+
                    '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_444,h_267,m_fast'"
                    alt="">
                    <div class="title-bottom">
                         <div class="vbox">
                        <div class="vtitle">合格动作</div>
                        <div class="vdata">Qualified action</div>
                         </div>
                    </div>
                </div>
                <div class="video-item video-item2"
                @click="selectVideo(cover.excellent)">
                <img src="../assets/img/capability/select2.png" alt=""
                        class="select" v-if="videourl.indexOf(cover.excellent)===-1&&isCompared">
                   <img src="../assets/img/capability/select.png" alt=""
                   class="select" v-if="videourl.indexOf(cover.excellent)>=0&&isCompared">
                    <img class="bg" :src="cover.excellent+
                    '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_444,h_267,m_fast'"
                    alt="">
                    <div class="title-bottom">
                         <div class="vbox">
                            <div class="vtitle">满分动作</div>
                            <div class="vdata">Full Score action</div>
                         </div>
                    </div>
                </div>
                <div class="video-item"
                 @click="selectVideo(cover.perfect)">
                    <img src="../assets/img/capability/select2.png" alt=""
                        class="select" v-if="videourl.indexOf(cover.perfect)===-1&&isCompared">
                    <img src="../assets/img/capability/select.png" alt=""
                     class="select" v-if="videourl.indexOf(cover.perfect)>=0&&isCompared">
                    <img class="bg" :src="cover.perfect+
                    '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_444,h_267,m_fast'"
                     alt="">
                     <div class="title-bottom">
                         <div class="vbox">
                            <div class="vtitle">卓越动作</div>
                            <div class="vdata">Excellent action</div>
                         </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="to" @click="gotoPage">
          <div class="to-left" >
              <div class="to-t1">Process System</div>
              <div class="to-t2">七级进阶体系</div>
          </div>
          <img src="../assets/img/home/right.png" alt="">
      </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3 from 'd3';
// eslint-disable-next-line no-unused-vars
import Chart from '../utils/chart_copy';

export default {
  data() {
    return {
      title: '',
      chart: '',
      config: '',
      num: 0,
      newdata: [],
      index: 0,
      upgrade: '',
      cover: '',
      user: '',
      type: '',
      thisnum: 0,
      showVideo: false,
      videourl: [],
      basicCapabilityArr: [],
      isCompared: false,
    };
  },
  created() {
    if (this.$route.params.index >= 0) {
      // eslint-disable-next-line radix
      this.thisnum = parseInt(this.$route.params.index);
      this.type = this.$route.params.type;
    }
  },
  mounted() {
    const that = this;
    this.user = JSON.parse(localStorage.getItem('student'));
    that.basicCapability();
    // setTimeout(() => {
    //   that.chart.renderText();
    //   that.chart.addMouseOn();
    // }, 1500);
  },
  methods: {
    openCompared() {
      this.isCompared = !this.isCompared;
    },
    // 选择视频
    selectVideo(url) {
      const num = this.videourl.indexOf(url);
      if (num >= 0) {
        this.videourl.splice(num, 1);
      } else {
        this.videourl.push(url);
      }
      if (this.isCompared) {
        if (this.videourl.length > 1) {
          this.showVideo = true;
        }
      } else {
        this.showVideo = true;
      }
    },
    onquit() {
      this.showVideo = false;
      this.isCompared = false;
      this.videourl = [];
    },
    onSwipeRight() {
      this.$router.go(-1);
    },
    gotoPage() {
      this.$router.push({
        name: 'CurriculumStructure',
      });
    },
    capability() {
      const that = this;
      this.$server.fetch('/api/study/report/capability', {
        student_id: this.user.student_id,
        capability: this.type,
      }).then((res) => {
        if (res.code === 200) {
          that.cover = res.data.cover;
        }
      });
    },
    //   初始化图表
    initChart() {
      const that = this;
      const chart = new Chart();
      that.chart = chart;
      const config = {
        margins: {
          top: 80, left: 80, bottom: 50, right: 80,
        }, // 位置信息
        textColor: 'black', // 项标题
        title: '学情报告', // 标题
        radius: 150, // 大小？
        animateDuration: 1000, // 动作缓冲时间
        tickNum: 5, // 雷达间隔
        axisfillColor: ['#fff', '#fff'], // 背景间隔
        linefillColor: ['rgba(255, 166, 37, 0.1)', 'rgba(237, 202, 126, 0.05)'],
        polylineColor: ['#FFA625', '#eeca7f'], // 折线颜色
        axisStrokeColor: '#eee', // 雷达线格颜色
        pointsColor: '#FFA625', // 圆点颜色
        pointsSize: 3, // 点大小
      };
      that.config = config;
      const data = that.basicCapabilityArr;
      that.newdata = data;
      // eslint-disable-next-line no-debugger
      /* ----------------------------配置参数------------------------  */
      that.chart.margins(that.config.margins);

      /* ----------------------------尺度转换------------------------  */
      chart.scaleRadius = d3.scaleLinear()
        .domain([0, 100])
        .range([0, config.radius]);

      /* ----------------------------渲染坐标轴------------------------  */
      chart.renderAxes = function () {
      // ----渲染背景多边形-----
        const points = that.getPolygonPoints(data.length, config.radius, config.tickNum);
        // eslint-disable-next-line no-debugger
        // debugger;
        const axes = chart.body().append('g')
          .attr('class', 'axes')
          .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2})`)
          .selectAll('axis')
          .data(points);

        axes.enter()
          .append('polygon')
          .attr('class', 'axis')
          .merge(axes)
          .attr('points', (d) => d)
          .attr('fill', (d, i) => (i % 2 === 0 ? config.axisfillColor[0] : config.axisfillColor[1]))
          .attr('stroke', config.axisStrokeColor);

        axes.exit()
          .remove();

        // ----渲染对角线-----
        const line = d3.line();

        const outerPoints = that.getOuterPoints(points[0]);

        const lines = d3.select('.axes')
          .selectAll('.line')
          .data(outerPoints);

        lines.enter()
          .append('path')
          .attr('class', 'line')
          .merge(lines)
          .attr('d', (d) => line([
            [0, 0],
            [d[0], d[1]],
          ]))
          .attr('stroke', config.axisStrokeColor);

        lines.exit()
          .remove();
      };

      /* ----------------------------渲染文本标签------------------------  */
      chart.renderText = function () {
        // eslint-disable-next-line no-debugger
        const texts = d3.select('.axes')
          .selectAll('.label')
          .data(data);
        texts.enter()
          .append('image')
          .attr('class', 'label')
          .merge(texts)
          .attr('xlink:href', (d, i) => `https://alicdn.joyballet.cn/studentStudy/capability/icon/${d.subject}-${i === that.thisnum ? 2 : 1}.png`)
        // eslint-disable-next-line no-mixed-operators
          .attr('x', (d, i) => Math.sin(i * Math.PI * 2 / data.length) * (config.radius + 50) - 28)
        // eslint-disable-next-line no-mixed-operators
          .attr('y', (d, i) => Math.cos(i * Math.PI * 2 / data.length) * (config.radius + 50) - 28)
          .attr('index', (d, i) => i)
          .attr('subject', (d) => d.subject)
          .attr('width', 56)
          .attr('height', 56);
      };

      /* ----------------------------渲染数据多边形------------------------  */
      chart.renderPolygons = function () {
        const newData = that.handleData(data);
        const polygons = chart.body().selectAll('.polygons')
          .data(newData);

        polygons.enter()
          .append('g')
          .attr('class', (d) => `g-${d.person}`)
          .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2})`)
          .append('polygon')
          .attr('class', 'polygon')
          .merge(polygons)
        // .attr('fill', 'none')
          .attr('fill', (d, i) => (that.index === i ? config.linefillColor[0] : config.linefillColor[1]))
        // (d, i) => chart.colors(i)
        // eslint-disable-next-line no-underscore-dangle
          .attr('stroke', (d, i) => (that.index === i ? config.polylineColor[0] : config.polylineColor[1]))
          .attr('stroke-width', '2')
          .attr('points', (d) => {
            const miniPolygon = [];
            d.forEach(() => {
              miniPolygon.push('0,0');
            });
            return miniPolygon.join(' ');
          })
          .transition()
          .duration(config.animateDuration)
          .attr('points', that.generatePolygons);

        polygons.exit()
          .remove();
      };

      /* ----------------------------渲染图标题------------------------  */
      chart.renderTitle = function () {
        chart.svg().append('text')
          .classed('title', true)
          .attr('x', chart.width() / 2)
          .attr('y', 0)
          .attr('dy', '2em')
          .text(config.title)
          .attr('fill', config.textColor)
          .attr('text-anchor', 'middle')
          .attr('stroke', config.textColor);
      };

      /* ----------------------------绑定鼠标交互事件------------------------  */
      chart.addMouseOn = function () {
        // eslint-disable-next-line no-shadow
        d3.selectAll('.label')
          .on('click', (node, k) => {
            const index = k.person2;
            // eslint-disable-next-line radix
            if (parseInt(index) === 1) return;
            const texts = d3.select('.axes')
              .selectAll('.label')
              .data(that.newdata);
            // that.type = node.path[0].getAttribute('subject');
            that.type = k.subject;
            that.capability();
            texts.enter()
              .append('image')
              .attr('class', 'label')
              .merge(texts)
              .attr('xlink:href', (d, i) => {
                // eslint-disable-next-line radix
                const num = (parseInt(index) === i ? 2 : 1);
                return `https://alicdn.joyballet.cn/studentStudy/capability/icon/${d.subject}-${num}.png`;
              });
            // alert(12);
          });
      };

      chart.render = function () {
        //   chart.renderTitle();

        chart.renderAxes();

        chart.renderText();

        chart.renderPolygons();

        chart.addMouseOn();
      };

      chart.renderChart('.capability-box2');
      console.log(chart);
    },
    basicCapability() {
      const that = this;
      this.$server.fetch('/api/study/report/basicCapability', {
        student_id: this.user.student_id,
      }).then((res) => {
        if (res.code === 200) {
          const arr = [];
          const { data } = res;
          let kk = 0;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in data) {
            if (key) {
              const obj = {};
              obj.subject = key;
              const obj2 = {
                person1: res.data[key].score.standard,
                person2: kk,
              };
              Object.assign(obj, obj2);
              arr.push(obj);
              kk += 1;
            }
          }
          if (!this.type) {
            that.type = arr[0].subject;
          }

          that.capability(arr[0].subject);
          that.basicCapabilityArr = arr;
          that.initChart();
        }
      });
    },
    // 计算多边形的顶点并生成顶点圆圈
    generatePolygons(d, index) {
      console.log(index);
      const that = this;
      const points = [];
      // eslint-disable-next-line no-mixed-operators
      const anglePiece = Math.PI * 2 / d.length;

      d.forEach((item, i) => {
        const x = Math.sin(i * anglePiece) * that.chart.scaleRadius(item[1]);
        const y = Math.cos(i * anglePiece) * that.chart.scaleRadius(item[1]);

        // 添加交点圆圈
        d3.select(`.g-${d.person}`)
          .append('circle')
          .attr('class', `point-${d.person}`)
          .attr('fill', that.config.pointsColor)
          .attr('stroke', '#fff')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', that.config.pointsSize)
          .attr('opacity', 0.6)
          .transition()
          .duration(that.config.animateDuration)
          .attr('cx', x)
          .attr('cy', y);

        points.push(`${x},${y}`);
      });

      return points.join(' ');
    },
    // 处理数据，转化数据结构，方便渲染
    handleData(data) {
      const newData = [];
      Object.keys(data[0]).forEach((key) => {
        if (key !== 'subject') {
          const item = [];
          item.person = key;
          newData.push(item);
        }
      });

      data.forEach((d) => {
        newData.forEach((item, i) => {
          item.push([d.subject, d[`person${i + 1}`]]);
        });
      });
      return newData;
    },
    computeTextAnchor(data, i) {
      if (data.length < 3) return;

      // eslint-disable-next-line no-mixed-operators
      const angle = i * 360 / data.length;

      if (angle === 0 || Math.abs(angle - 180) < 0.01) {
        // eslint-disable-next-line consistent-return
        return 'middle';
      } if (angle > 180) {
        // eslint-disable-next-line consistent-return
        return 'end';
      }
      // eslint-disable-next-line consistent-return
      return 'start';
    },
    // 生成背景多边形的顶点
    getPolygonPoints(vertexNum, outerRadius, tickNum) {
      const points = [];
      let polygon;

      if (vertexNum < 3) return points;

      // eslint-disable-next-line no-mixed-operators
      const anglePiece = Math.PI * 2 / vertexNum;
      const radiusReduce = outerRadius / tickNum;

      for (let r = outerRadius; r > 0; r -= radiusReduce) {
        polygon = [];

        for (let i = 0; i < vertexNum; i += 1) {
          polygon.push(
            `${Math.sin(i * anglePiece) * r},${Math.cos(i * anglePiece) * r}`,
          );
        }

        points.push(polygon.join(' '));
      }

      return points;
    },

    // 得到最外层多边形的顶点
    getOuterPoints(outerPoints) {
      const points = outerPoints.split(' ').map((d) => d.split(','));
      return points;
    },
  },
};
</script>
<style lang="less">
    // .box{
    //      width: 100vw;
    //     height: 100vh;
    //     overflow: hidden;
    // }
    .mycapability{
      .g-person2{
        opacity: 0;
      }
        .back img {
              width: 0.82rem;
          }
        background: #FFFDFA;
        .box:nth-child(2) {
            display:none
        }
        .play-box{
            width: 100vw;
            height: 100vh;
            position: absolute;
            display: flex;
            align-items: center;
            justify-items: center;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            z-index: 100;
            .quit{
                width: 0.7rem;
                height: 0.3rem;
                border-radius: 0.07rem;
                border:0.01rem solid #fff;
                text-align: center;
                line-height: 0.3rem;
                color: #fff;
                position: absolute;
                right: 0.31rem;
                top: 0.25rem;
                z-index: 100;
            }
            .videoarr{
                display: flex;
                width: 9.6rem;
                margin: 0 auto;
            }
            .video-item{
                flex: 1;
                height: 7.03rem;
                margin: 0 0.12rem;
                display: flex;
                align-items: center;
                video{
                    width: 100%;
                }
            }
        }
    }
    .compared{
      width: 0.7rem;
      height: 0.31rem;
      line-height: 0.31rem;
      text-align: center;
      color: #fff;
      background: #EDCA7E;
      font-size: 0.14rem;
      border-radius: 0.07rem;
      position: absolute;
      right: 0.31rem;
      top: 0.25rem;
    }
    .videos{
        width: 100vw;
        position: absolute;
        top: 500px;
    }
    .capability-box2{
        width: 100vw;
        height: 100vh;
        // overflow: hidden;
        display: flex;
        justify-content: center;
        .box{
            margin-left: 0rem;
            margin-top: -0.5rem;
            transform: scale(0.7);
            transform-origin: center 0;
        }
    }
    .thisvideo{
        margin: 0 auto;
        font-weight: bold;
        // background: #fff;
        // padding: 0.1rem;
        // box-sizing: content-box;
        border-radius: 0.1rem;
        width: 2.17rem;
        margin-bottom: 0.2rem;
        // box-shadow: 0 2px 0.2rem #ddd;
        position: relative;
        transform: translateX(-2.44rem);
        z-index: 2;
        font-size: 0.26rem;
        .jiao{
            width:  0.2rem;
            height: 0.2rem;
            background: #fff;
            transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
            border-bottom-left-radius: 30%;
            position: absolute;
            left: 50%;
            margin-left: -0.03rem;
            box-shadow: 0 2px 0.2rem #ddd;
            bottom: -2px;
        }
        .thisvideo-box{
            width: 2.17rem;
            height: 1.3rem;
            border-radius: 0.1rem;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
            }
            .title-bottom{
                position: absolute;
                bottom: 0;
                width: 100%;
                height:100%;
                background-image:
                 linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.4));
                display: flex;
                align-items: flex-end;
                color: #fff;
                padding: 0.12rem;
                .vtitle{
                    font-size: 0.16rem;
                    line-height: 0.21rem;
                }
                .vdata{
                    font-size: 0.12rem;
                    color: rgba(255, 255, 255, 0.493);
                }
            }
        }
    }
    .videos-list{
        display: flex;
        justify-content: center;
        align-items: center;
        .video-item{
            width: 2.17rem;
            height: 1.3rem;
            border-radius: 0.08rem;
            overflow: hidden;
            box-sizing: content-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.15rem;
            .bg{
                display: block;
                width: 104%;
                height: 104%;
            }
            .select{
              width: 0.12rem;
              height: 0.12rem;
              position: absolute;
              right: 0.16rem;
              top: 0.16rem;
            }
            position: relative;
            .title-bottom{
                position: absolute;
                bottom: 0;
                width: 100%;
                height:100%;
                background-image:
                 linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.4));
                display: flex;
                align-items: flex-end;
                color: #fff;
                padding: 0.12rem;
                .vtitle{
                    font-size: 0.16rem;
                    line-height: 0.21rem;
                }
                .vdata{
                    font-size: 0.12rem;
                    color: rgba(255, 255, 255, 0.493);
                }
            }
        }
        .video-item2{
            // border: 3px solid #FCE6B4;
        }
    }
    .capability{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .title{
            position: fixed;
            top: 0.65rem;
            left: 0.31rem;
            text-align: left;
            padding-top: 0.38rem;
            background: url('../assets/img/capability/title.png') no-repeat;
            background-size: 1.39rem 0.52rem;
            .t2{
                font-size: 0.31rem;
                font-weight: bold;
            }
            p{
                font-size: 0.16rem;
                opacity: 0.6;
            }
        }
        .container {
            margin: 30px auto;
            width: 600px;
            height: 300px;
        }
    }
</style>
